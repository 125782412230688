<template>
    <div class="board__view">
        <div class="board__qna-item board-item__tit">
            <div class="board__qna-item">
                <div class="board__qna-item__row">
                    <v-row align="center">
                        <v-col cols="auto">
                            <h3 class="tit tit--sm primary--text">Q</h3>
                        </v-col>
                        <v-col cols="auto">
                            <v-chip color="primary-50" text-color="primary">
                                {{ faq?.category }}
                            </v-chip>
                        </v-col>
                        <v-col class="board__qna-item__tit">
                            <p class="page-text grey-1--text font-weight-medium ellip">
                                {{ faq?.subject }}
                            </p>
                        </v-col>
                    </v-row>
                </div>
                <div class="board__qna-item__contents board__qna-item__row">
                    <v-row align="center">
                        <v-col cols="auto">
                            <h3 class="tit tit--sm grey-6--text">A</h3>
                        </v-col>
                        <v-col cols="auto">
                            <v-card elevation="0" class="hidden">
                                <v-img :src="`${faq.reply.thumb}`" :aspect-ratio="1 / 1" class="board__qna__img" />
                            </v-card>
                        </v-col>
                        <v-col class="page-text board__qna__txt">
                            <p class="font-weight-medium mb-2 mb-lg-4">
                                {{ faq?.reply?.name }}
                            </p>
                        </v-col>
                        <v-col cols="12" class="mt-8">
                            <p v-html="faq?.reply?.content" class="page-text grey-6--text"></p>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <div class="v-btn--group justify-start">
            <v-btn @click="$router.go(-1)" outlined color="primary" class="h-lg-40px">리스트</v-btn>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import BoardTit from "@/components/client/board/board-item__tit.vue";
import BoardCount from "@/components/client/board/board-count.vue";

export default {
    components: {
        BoardTit,
        BoardCount,
    },

    data() {
        return {
            faq: {},
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { faq } = await api.v1.center.faqs.get({ _id: this._faq });
            this.faq = faq;
        },
    },
    computed: {
        _faq() {
            return this.$route.params._faq;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped></style>
