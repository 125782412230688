var render = function render(){
  var _vm$faq, _vm$faq2, _vm$faq3, _vm$faq3$reply, _vm$faq4, _vm$faq4$reply;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__view"
  }, [_c('div', {
    staticClass: "board__qna-item board-item__tit"
  }, [_c('div', {
    staticClass: "board__qna-item"
  }, [_c('div', {
    staticClass: "board__qna-item__row"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm primary--text"
  }, [_vm._v("Q")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$faq = _vm.faq) === null || _vm$faq === void 0 ? void 0 : _vm$faq.category) + " ")])], 1), _c('v-col', {
    staticClass: "board__qna-item__tit"
  }, [_c('p', {
    staticClass: "page-text grey-1--text font-weight-medium ellip"
  }, [_vm._v(" " + _vm._s((_vm$faq2 = _vm.faq) === null || _vm$faq2 === void 0 ? void 0 : _vm$faq2.subject) + " ")])])], 1)], 1), _c('div', {
    staticClass: "board__qna-item__contents board__qna-item__row"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm grey-6--text"
  }, [_vm._v("A")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "hidden",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-img', {
    staticClass: "board__qna__img",
    attrs: {
      "src": `${_vm.faq.reply.thumb}`,
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "page-text board__qna__txt"
  }, [_c('p', {
    staticClass: "font-weight-medium mb-2 mb-lg-4"
  }, [_vm._v(" " + _vm._s((_vm$faq3 = _vm.faq) === null || _vm$faq3 === void 0 ? void 0 : (_vm$faq3$reply = _vm$faq3.reply) === null || _vm$faq3$reply === void 0 ? void 0 : _vm$faq3$reply.name) + " ")])]), _c('v-col', {
    staticClass: "mt-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-text grey-6--text",
    domProps: {
      "innerHTML": _vm._s((_vm$faq4 = _vm.faq) === null || _vm$faq4 === void 0 ? void 0 : (_vm$faq4$reply = _vm$faq4.reply) === null || _vm$faq4$reply === void 0 ? void 0 : _vm$faq4$reply.content)
    }
  })])], 1)], 1)])]), _c('div', {
    staticClass: "v-btn--group justify-start"
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }